import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSubscription, reset } from '../features/stripe/stripeSlice';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import TermsAndConditions from '../components/TermsAndConditions';

function PaymentPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedPlan = queryParams.get("plan") || "basic"; // Default to "basic" if no plan is provided

  const [billingCycle, setBillingCycle] = useState('monthly');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [plan, setPlan] = useState(selectedPlan); // Set the plan from URL

  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { clientSecret, isLoading, isError, message } = useSelector((state) => state.stripe);
  const userEmail = useSelector((state) => state.auth.user.email);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Update plan if URL parameter changes
    setPlan(selectedPlan);
  }, [selectedPlan]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    if (!agreeToTerms) {
      alert('Please agree to the Terms and Conditions.');
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.error(error.message);
      return;
    }

    dispatch(
      createSubscription({
        email: userEmail,
        paymentMethodId: paymentMethod.id,
        billingCycle,
        plan,
      })
    );
  };

  // Pricing based on the selected plan and billing cycle
  const monthlyPrice = plan === 'basic' ? '$2.00' : plan === 'standard' ? '$5.00' : '$10.00';
  const yearlyPrice = plan === 'basic' ? '$15.00' : plan === 'standard' ? '$50.00' : '$80.00';

  return (
    <section className="min-h-screen bg-[#f7f5f2] text-gray-800 py-32 px-8">
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10 max-w-5xl">
        {/* Payment Details */}
        <div className="bg-white shadow-lg rounded-lg p-8">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-4">Try free for 2 weeks</h1>
          <p className="text-gray-600 mb-6">Or continue with the {plan.charAt(0).toUpperCase() + plan.slice(1)} plan</p>
          
          <h2 className="text-lg font-bold text-gray-800 mb-4">Enter payment details</h2>

          <div className="mb-6">
            <label className="block mb-2 text-gray-600">Billing cycle</label>
            <div className="flex gap-4">
              <button
                type="button"
                className={`w-1/2 border border-gray-300 rounded-lg p-4 text-center ${billingCycle === 'monthly' ? 'bg-gray-100' : ''}`}
                onClick={() => setBillingCycle('monthly')}
              >
                <h3 className="text-lg font-bold">{monthlyPrice} / month</h3>
                <p className="text-gray-500">Billed monthly</p>
              </button>
              <button
                type="button"
                className={`w-1/2 border border-gray-300 rounded-lg p-4 text-center ${billingCycle === 'yearly' ? 'bg-gray-100' : ''}`}
                onClick={() => setBillingCycle('yearly')}
              >
                <h3 className="text-lg font-bold">{yearlyPrice} / year</h3>
                <p className="text-gray-500">Save with yearly billing</p>
              </button>
            </div>
          </div>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="w-full p-3 border border-gray-300 rounded-lg">
              <CardElement />
            </div>

            <label className="flex items-center gap-2 text-gray-600">
              <input 
                type="checkbox" 
                className="text-indigo-600" 
                checked={agreeToTerms} 
                onChange={() => setAgreeToTerms(!agreeToTerms)} 
              />
              I agree to the 
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="text-indigo-600 underline ml-1"
              >
                Terms and Conditions
              </button>.
            </label>
            
            <button
              type="submit"
              className="w-full px-4 py-3 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition duration-200"
              disabled={isLoading || !agreeToTerms}
            >
              {isLoading ? 'Processing...' : 'Start Free Trial'}
            </button>

            {isError && <p className="text-red-500 mt-2">{message}</p>}
          </form>
        </div>

        {/* Summary */}
        <div className="bg-gray-100 rounded-lg p-8">
          <h2 className="text-xl font-bold text-gray-900 mb-4">Summary</h2>
          <p className="text-gray-700 mb-2">{plan.charAt(0).toUpperCase() + plan.slice(1)} Plan</p>
          <ul className="space-y-2 mb-6">
            {plan === 'basic' && (
              <>
                <li className="flex items-center gap-2">
                  <span className="text-gray-700">✔️</span> 1 account connection
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-gray-700">✔️</span> Up to 500 review requests
                </li>
              </>
            )}
            {plan === 'standard' && (
              <>
                <li className="flex items-center gap-2">
                  <span className="text-gray-700">✔️</span> 3 account connections
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-gray-700">✔️</span> Up to 1,000 review requests
                </li>
              </>
            )}
            {plan === 'premium' && (
              <>
                <li className="flex items-center gap-2">
                  <span className="text-gray-700">✔️</span> Up to 10 account connections
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-gray-700">✔️</span> Up to 10,000 review requests
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-gray-700">✔️</span> Priority email support
                </li>
              </>
            )}
          </ul>

          <div className="flex justify-between items-center text-gray-700 mb-2">
            <span>Billing Cycle</span>
            <span>{billingCycle === 'monthly' ? 'Monthly' : 'Yearly'}</span>
          </div>
          <div className="flex justify-between items-center text-gray-700 mb-4">
            <span>Subtotal</span>
            <span>{billingCycle === 'monthly' ? monthlyPrice : yearlyPrice}</span>
          </div>

          <div className="text-xl font-bold text-gray-800">Billed now</div>
          <div className="text-3xl font-extrabold text-indigo-600">$0.00</div>
          <p className="text-gray-600 mt-2">Trial ends in 2 weeks.</p>
        </div>
      </div>
      <TermsAndConditions isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </section>
  );
}

export default PaymentPage;
