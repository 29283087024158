import React from 'react';
import amazonReviewSvg from '../assets/Review.svg';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen bg-[#f7f5f2] text-gray-800 justify-center">
      
      {/* New Intro Section */}
      <section className="flex flex-col items-center lg:items-start lg:flex-row lg:justify-center max-w-1xl mx-auto px-4 sm:px-6 lg:px-8">
        
        {/* Text Content */}
        <div className="flex-grow lg:flex-shrink-0 lg:basis-1/2 max-w-2xl text-center lg:text-left lg:mr-12">
          {/* New Label */}
          <div className="flex items-center space-x-2 mb-4">
            <span className="px-3 py-1 bg-indigo-600 text-white rounded-full text-lg font-semibold">New</span>
            <span className="text-indigo-600 font-medium text-4xl">Introducing SellerAutomate</span>
          </div>
          
          {/* Main Headline */}
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-8xl leading-tight tracking-tight mb-4">
            Automate Reviews.<br />
            Boost Credibility.
          </h1>
          
          {/* Description */}
          <p className="mt-4 text-gray-500 md:text-3xl">
            Discover SellerAutomate, the simple way to automate Amazon review requests. Increase customer trust and drive more sales with minimal effort.
          </p>

          {/* Buttons */}
          <div className="mt-8 flex gap-4 justify-center lg:justify-start">
            <Link
              to="/login"
              className="px-8 py-4 bg-indigo-600 text-white rounded-full text-xl font-medium hover:bg-indigo-700 transition duration-200 flex items-center space-x-2"
            >
              <span>Get Started with SellerAutomate</span>
              <span>→</span>
            </Link>
            <Link
              to="/plans"
              className="px-8 py-4 text-xl font-medium text-gray-800 border-b-2 border-transparent hover:border-gray-800 transition duration-200 flex items-center space-x-2"
            >
              <span>View Pricing Plans</span>
              <span>→</span>
            </Link>
          </div>
        </div>

        {/* SVG Section */}
        <div className="flex-grow-0 lg:flex-shrink-0 lg:basis-1/2 mt-10 lg:mt-0">
          <img
            className="w-full object-cover rounded-lg"
            src={amazonReviewSvg}
            alt="Automated Amazon review requests illustration"
          />
        </div>
      </section>
    </div>
  );
};

export default Home;
