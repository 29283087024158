// File: stripeService.js
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const API_URL = `${apiUrl}/api/stripe`;

// Create a subscription on the backend
const createSubscription = async (email, paymentMethodId, billingCycle, plan) => { // Add 'plan' parameter
  const response = await axios.post(`${API_URL}/create-subscription`, {
    email,
    paymentMethodId,
    billingCycle,
    plan, // Include 'plan' in the request payload
  });
  return response.data;
};

const stripeService = {
  createSubscription,
};

export default stripeService;
