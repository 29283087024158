import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import stripeReducer from './features/stripe/stripeSlice';
import amazonReducer from './features/amazon/amazonSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,        // Existing auth reducer
    stripe: stripeReducer,     // Add the stripe reducer here
    amazon: amazonReducer,
  },
});

export default store;
