import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const AMAZON_API_URL = `${apiUrl}/api/amazon`;


// Initiate Amazon OAuth login
const initiateAmazonLogin = async (user) => {
  const response = await axios.get(`${AMAZON_API_URL}/login`, {
    params: { userId: user._id },
    withCredentials: true,
  });
  return response.data; // Should contain { authUrl }
};

// Fetch orders (example of additional function for Amazon SP-API requests)
const fetchOrders = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${AMAZON_API_URL}/orders`, config);
  return response.data;
};

const fetchConnections = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { userId },
  };

  const response = await axios.get(`${AMAZON_API_URL}/connections`, config);
  return response.data;
};


const deleteConnection = async (connectionId, token, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { userId },
  };
  const response = await axios.delete(`${AMAZON_API_URL}/connections/${connectionId}`, config);
  return response.data;
};

const amazonService = {
  initiateAmazonLogin,
  fetchOrders,
  fetchConnections,
  deleteConnection,
};

export default amazonService;
