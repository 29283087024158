import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { forgotPassword, reset, setMessage, setError } from '../features/auth/authSlice';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errorShake, setErrorShake] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, isError } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isError) {
      setErrorShake(true);
      setTimeout(() => setErrorShake(false), 800);
    }
    dispatch(reset());
  }, [isError, dispatch]);

  const onSubmitForgot = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      dispatch(setError(true));
      dispatch(setMessage('Email Syntax is Incorrect'));
    } else {
      dispatch(forgotPassword({ email }));
      toast.success('If a user exists, an email has been sent.', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#f7f5f2] text-gray-800">
      <ToastContainer position="top-right" autoClose={5000} />
      <div className="max-w-md w-full p-8 bg-white rounded-lg shadow-lg border border-gray-200">
        <h2 className="text-3xl font-extrabold text-gray-800 mb-6 text-center">
          Forgot Password
        </h2>
        <form onSubmit={onSubmitForgot}>
          <div className={`mb-5 ${errorShake ? 'animate-shake' : ''}`}>
            <label htmlFor="email" className="block text-sm font-medium text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full p-3 rounded bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full py-3 mt-6 bg-indigo-600 text-white font-medium rounded hover:bg-indigo-700 transition duration-150"
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
