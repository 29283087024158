import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../features/auth/authSlice';
import lightLogo from '../assets/Light_Mode_Logo.png';
import darkLogo from '../assets/Dark_Mode_Logo.png';
import { SunIcon, MoonIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token, user } = useSelector((state) => state.auth); // Adjust according to your state structure
  const isLoggedIn = token && user;

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <header className="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 py-4 shadow-md border-b border-gray-200 dark:border-gray-700">
      <div className="w-full flex justify-between max-w-1xl mx-auto px-6 sm:px-8 lg:px-10">
        {/* Logo Section */}
        <div className="flex items-end space-x-10">
          <a href="/">
            <img
              src={isDarkMode ? darkLogo : lightLogo}
              alt="SellerAutomate Logo"
              style={{ height: '50px', width: 'auto' }}
            />
          </a>
          {!isLoggedIn && (
            <a
              href="/plans"
              className="text-lg font-semibold text-gray-700 dark:text-gray-300 hover:text-indigo-500 transition duration-200"
            >
              Pricing
            </a>
          )}
        </div>

        {/* Navigation Links */}
        <nav className="flex items-center space-x-4 text-base font-medium">
          {/* Dark Mode Toggle Button */}
          <button
            onClick={toggleDarkMode}
            className="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-200"
            title={isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
          >
            {isDarkMode ? (
              <SunIcon className="w-5 h-5 text-yellow-400" />
            ) : (
              <MoonIcon className="w-5 h-5 text-indigo-500" />
            )}
          </button>

          {/* Dashboard Button */}
          {isLoggedIn && (
            <a
              href="/dashboard"
              className="flex items-center justify-center px-4 py-2 text-gray-800 dark:text-gray-200 bg-indigo-500 hover:bg-indigo-600 rounded-lg font-medium transition duration-200"
              title="Go to Dashboard"
            >
              Dashboard
            </a>
          )}

          {isLoggedIn && (
            <button
              onClick={handleLogout}
              className="flex items-center space-x-2 hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-300 px-3 py-2 rounded-lg transition duration-200"
              title="Logout"
            >
              <ArrowRightOnRectangleIcon className="w-5 h-5" />
              <span className="hidden sm:inline">Logout</span>
            </button>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
