import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const API_URL = `${apiUrl}/api/users/`;

// Register user
const register = async (userData) => {
  const response = await axios.post(`${API_URL}register`, userData);
  return response.data;
};

// Login user
const login = async (userData) => {
  const response = await axios.post(`${API_URL}login`, userData);
  return response.data;
};

// Get user profile
const getUserProfile = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}me`, config);
  return response.data;
};

// Update user profile
const updateProfile = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${API_URL}profile`, userData, config);
  return response.data;
};

const connectAmazon = async () => {
  const response = await axios.get(`${API_URL}auth/amazon-login`, { withCredentials: true });
  return response.data.authUrl;
};

// Verify user
const verifyUser = async (token) => {
  const response = await axios.get(`${API_URL}verify`, { params: { token } });
  return response.data;
};

const changePassword = async (passwordData, token) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.put(`${API_URL}password`, passwordData, config);
  return response.data;
};

const deleteUserAccount = async (token) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(`${API_URL}delete`, config);
  return response.data;
};

// Forgot Password
const forgotPassword = async (userData) => {
  const response = await axios.post(`${API_URL}forgotPassword`, userData)
  return response.data
}

// Confirm Forgot Password
const forgotPasswordConfirm = async (userData) => {
  const response = await axios.post(
    `${API_URL}forgot-password-confirm`,
    userData
  )
  return response.data
}

const authService = {
  register,
  login,
  getUserProfile,
  updateProfile,
  connectAmazon,
  verifyUser,
  changePassword,
  deleteUserAccount,
  forgotPassword,
  forgotPasswordConfirm,
};

export default authService;
