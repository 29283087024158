import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import amazonService from './amazonService';

const initialState = {
  isConnected: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const connectAmazon = createAsyncThunk('amazon/connectAmazon', async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const user = state.auth.user;
    const { authUrl } = await amazonService.initiateAmazonLogin(user);
    window.location.href = authUrl;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message || error.toString());
  }
});

export const fetchAmazonConnections = createAsyncThunk(
  'amazon/fetchConnections',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const userId = state.auth.user._id;
      const token = state.auth.token;
      return await amazonService.fetchConnections(userId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message || error.toString());
    }
  }
);

export const fetchAmazonOrders = createAsyncThunk('amazon/fetchOrders', async (token, thunkAPI) => {
  try {
    const orders = await amazonService.fetchOrders(token);
    return orders;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message || error.toString());
  }
});

export const deleteConnection = createAsyncThunk(
  'amazon/deleteConnection',
  async (connectionId, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.token;
      const userId = state.auth.user._id;
      await amazonService.deleteConnection(connectionId, token, userId);
      return connectionId; // Return connectionId to remove it from state
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message || error.toString());
    }
  }
);


const amazonSlice = createSlice({
  name: 'amazon',
  initialState,
  reducers: {
    resetAmazonState: (state) => {
      state.isConnected = false;
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(connectAmazon.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(connectAmazon.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isConnected = true;
      })
      .addCase(connectAmazon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchAmazonOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAmazonOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // You can handle the fetched orders here if needed
      })
      .addCase(fetchAmazonOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchAmazonConnections.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAmazonConnections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.connections = action.payload; // Store fetched connections
      })
      .addCase(fetchAmazonConnections.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteConnection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteConnection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.connections = state.connections.filter(
          (connection) => connection._id !== action.payload
        ); // Remove deleted connection
      })
      .addCase(deleteConnection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resetAmazonState } = amazonSlice.actions;
export default amazonSlice.reducer;
