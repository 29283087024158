import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-8">
      <div className="container mx-auto text-center">
        <p className="text-sm sm:text-base font-medium">
          &copy; {new Date().getFullYear()} SellerAutomate. All rights reserved.
        </p>
        <p className="text-xs mt-2 text-gray-500">
          Designed to simplify your Amazon review requests.
        </p>
      </div>
    </footer>
  );
};

export default Footer;