import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from './authService';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  token: localStorage.getItem('token') || null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

const updateLocalStorage = (state) => {
  if (state.user) {
    localStorage.setItem('user', JSON.stringify(state.user));
    localStorage.setItem('token', state.token);
  } else {
    clearLocalStorage();
  }
};

const clearLocalStorage = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
};

// Register user
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
  try {
    return await authService.register(user);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message || error.toString());
  }
});

export const connectAmazon = createAsyncThunk('auth/connectAmazon', async (_, thunkAPI) => {
  try {
    const authUrl = await authService.connectAmazon();
    window.location.href = authUrl; // Redirect the user to the Amazon authorization URL
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message || error.toString());
  }
});

// Login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    const response = await authService.login(user);
    const authData = {
      user: response.user, // Extract the user object from the response
      token: response.token,
      message: response.message
    };
    thunkAPI.dispatch(setAuthData(authData));
    return authData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message || error.toString());
  }
});

// Logout user
export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(clearState());
  } catch (error) {
    console.error('Error during logout:', error);
  }
});

export const verifyUser = createAsyncThunk('auth/verifyUser', async ({ token }, thunkAPI) => {
  try {
    const response = await authService.verifyUser(token); // Use token for verification
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || error.toString());
  }
});

export const updateProfile = createAsyncThunk('auth/updateProfile', async ({ userData, token }, thunkAPI) => {
  try {
    return await authService.updateProfile(userData, token);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message || error.toString());
  }
});

export const changePassword = createAsyncThunk('auth/changePassword', async (passwordData, thunkAPI) => {
  try {
    return await authService.changePassword(passwordData, thunkAPI.getState().auth.token);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message || error.toString());
  }
});

export const deleteUserAccount = createAsyncThunk('auth/deleteUserAccount', async (_, thunkAPI) => {
  try {
    return await authService.deleteUserAccount(thunkAPI.getState().auth.token);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message || error.toString());
  }
});

export const getUserProfile = createAsyncThunk('auth/getUserProfile', async (token, thunkAPI) => {
  try {
    return await authService.getUserProfile(token);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message || error.toString());
  }
});

// Async thunk for forgot password email
export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (userData, thunkAPI) => {
    try {
      return await authService.forgotPassword(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        'Failed to Send Forgot Password Email';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Async thunk for forgot password reset after getting email
export const forgotPasswordConfirm = createAsyncThunk(
  'auth/forgotPasswordConfirm',
  async (userData, thunkAPI) => {
    try {
      return await authService.forgotPasswordConfirm(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        'Failed to Reset Password';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      updateLocalStorage(state);
    },
    clearState: (state) => {
      state.user = null;
      state.token = null;
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
      clearLocalStorage();
      console.log('Logged out and state cleared');
    },
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        updateLocalStorage(state);
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(connectAmazon.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(connectAmazon.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(connectAmazon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.isError = false;
        state.isSuccess = false;
        state.isLoading = false;
        state.message = '';
      })
      .addCase(verifyUser.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user.isVerified = true; // Update verification status
        updateLocalStorage(state); // Update local storage
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setAuthData, clearState, reset, setError, setMessage } = authSlice.actions;
export default authSlice.reducer;
