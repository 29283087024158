import React from 'react';
import { Outlet } from 'react-router-dom';
import DashSideBar from '../components/DashSideBar';

const Dashboard = () => {
  return (
    <div className="flex">
      <DashSideBar isSidebarOpen={true} setIsSidebarOpen={() => {}} isSidebarCollapsed={false} />
      <main className="flex-grow">
        <Outlet /> {/* This will render the nested route's component */}
      </main>
    </div>
  );
};

export default Dashboard;
