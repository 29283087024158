import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from './components/Layout';
import Home from './pages/Home';
import Register from './pages/Register';
import ProfileSettings from './pages/ProfileSettings';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Plans from './pages/Plans';
import VerifyAndLogin from './pages/Verify';
import PaymentPage from './pages/PaymentPage';
import ConnectionsMangement from './pages/ConnectionsMangement';
import Dashboard from './pages/Dashboard';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './index.css';

// Initialize Stripe with your public key
const stripePromise = loadStripe('pk_test_51QK2Et06nvRKI5juzZRAWqKRu5ipwHTajOLKABHlgdIRwJgKIS8BRhQc69qMbWPbX8u6E5DUb5t6rzJI6IhaCz0D00gH2hCyhh');

function ProtectedRoute({ children }) {
  const { token, user } = useSelector((state) => state.auth);
  const isLoggedIn = token && user;

  return isLoggedIn ? children : <Navigate to="/login" replace />;
}


function PublicRoute({ children }) {
  const { token, user } = useSelector((state) => state.auth);
  const isLoggedIn = token && user;

  return isLoggedIn ? <Navigate to="/dashboard" replace /> : children;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* Public Routes */}
          <Route
            index
            element={
              <PublicRoute>
                <Home />
              </PublicRoute>
            }
          />
          <Route
            path="register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path="login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="forgot-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />

          {/* Private Routes */}
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route
              path="profile"
              element={
                <ProtectedRoute>
                  <ProfileSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="connections"
              element={
                <ProtectedRoute>
                  <ConnectionsMangement />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Protected Route with Stripe */}
          <Route
            path="payment"
            element={
              <ProtectedRoute>
                <Elements stripe={stripePromise}>
                  <PaymentPage />
                </Elements>
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
