// File: stripeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import stripeService from './stripeService';

const initialState = {
  clientSecret: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// Create subscription with Stripe
export const createSubscription = createAsyncThunk(
  'stripe/createSubscription',
  async ({ email, paymentMethodId, billingCycle, plan }, thunkAPI) => { // Add 'plan' parameter
    try {
      return await stripeService.createSubscription(email, paymentMethodId, billingCycle, plan); // Pass 'plan' to the service
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message || error.toString());
    }
  }
);

const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
      state.clientSecret = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clientSecret = action.payload.clientSecret;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = stripeSlice.actions;
export default stripeSlice.reducer;
