import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ClipboardDocumentCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
  LinkIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline';
import Logo from '../assets/Logo_Indigo_Cropped.png';

const DashSideBar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  isSidebarCollapsed,
}) => {
  const { user, profileImageUrl } = useSelector((state) => state.auth);

  const sections = [
    {
      title: 'Overview',
      items: [
        {
          name: 'Dashboard',
          link: '/sa/dashboard',
          icon: (
            <Squares2X2Icon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
        },
      ],
    },
    {
      title: 'Manage Connections',
      items: [
        {
          name: 'Edit Connections',
          link: '/dashboard/connections',
          icon: (
            <LinkIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
        },
      ],
    },
    {
      title: 'Settings',
      items: [
        {
          name: 'Profile Details',
          link: '/dashboard/profile',
          icon: (
            <ClipboardDocumentCheckIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
        },
        {
          name: 'Subscription Management',
          link: '/sa/subscriptions',
          icon: (
            <CreditCardIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
        },
      ],
    },
  ];
  

  return (
    <div
      className={`fixed inset-0 z-50 transition-transform transform ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } lg:translate-x-0 lg:static lg:z-auto lg:inset-auto bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 shadow-lg min-h-screen ${
        isSidebarCollapsed ? 'lg:w-16' : 'lg:w-64'
      } w-72 flex flex-col justify-between`}
    >
      <div className='flex flex-col h-full'>
        {/* Top Section: Logo and Close Button for Mobile */}
        <div className='flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 lg:hidden'>
          <NavLink to='/' className='flex items-center'>
            <img src={Logo} className='h-8 w-auto' alt='Logo' />
          </NavLink>
          <button
            className='text-gray-500 dark:text-gray-400'
            onClick={() => setIsSidebarOpen(false)}
          >
            <XMarkIcon className='w-6 h-6' />
          </button>
        </div>

        {/* Sidebar Content */}
        <div className='flex-grow overflow-y-auto'>
          {/* Expanded Sidebar Content: Always visible on mobile, conditional on desktop */}
          <div className={`${isSidebarCollapsed ? 'lg:hidden' : 'block'}`}>
            {/* User Info */}
            <div className='p-4'>
              <div className='flex items-center mb-4'>
                <img
                  src={
                    profileImageUrl ||
                    'https://cdn-icons-png.flaticon.com/512/9131/9131529.png'
                  }
                  className='h-10 w-10 rounded-full border-2 border-gray-300 dark:border-gray-600'
                  alt='User Avatar'
                />
                {/* User Name: Hidden on Desktop if Collapsed */}
                <div className={`${isSidebarCollapsed ? 'lg:hidden' : 'ml-3'}`}>
                  <h2 className='text-sm font-semibold text-gray-700 dark:text-gray-200'>
                    Welcome, {user?.name}
                  </h2>
                </div>
              </div>

              {/* Navigation Sections */}
              {sections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <h3 className='text-xs font-semibold uppercase tracking-wider text-gray-500 dark:text-gray-400 mb-2'>
                    {section.title}
                  </h3>
                  <nav className='space-y-1 mb-4'>
                    {section.items.map((item, index) => (
                      <NavLink
                        key={index}
                        to={item.link}
                        className='flex items-center p-2 text-gray-700 rounded-lg hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 transition'
                        activeClassName='bg-gray-200 dark:bg-gray-700'
                        onClick={() => {
                          setIsSidebarOpen(false);
                        }}
                      >
                        {item.icon}
                        {/* Item Name: Hidden on Desktop if Collapsed */}
                        <span
                          className={`ml-3 ${isSidebarCollapsed ? 'lg:hidden' : 'inline'
                            }`}
                        >
                          {item.name}
                        </span>
                      </NavLink>
                    ))}
                  </nav>
                </div>
              ))}
            </div>
          </div>

          {/* Sidebar Collapsed View on Desktop */}
          {isSidebarCollapsed && (
            <nav className='flex-col items-center p-4 space-y-2 lg:flex hidden'>
              {sections.map((section) =>
                section.items.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.link}
                    className='flex items-center justify-center p-4 text-gray-700 hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 rounded-lg transition'
                    activeClassName='bg-gray-200 dark:bg-gray-700'
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                  >
                    {React.cloneElement(item.icon, {
                      className: 'w-6 h-6 text-indigo-500',
                    })}
                  </NavLink>
                ))
              )}
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashSideBar;
