import React from 'react';

function TermsAndConditions({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50" onClick={onClose}>
      <div
        className="bg-white rounded-lg shadow-lg max-w-2xl w-full p-6 relative"
        onClick={(e) => e.stopPropagation()} // Prevents modal close when clicking inside
      >
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold text-gray-800">Terms and Conditions</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-800 focus:outline-none">
            ✕
          </button>
        </div>

        {/* Body */}
        <div className="space-y-4 text-gray-600 text-base leading-relaxed max-h-[60vh] overflow-y-auto">
          <p>
            Welcome to SellerAutomate! By subscribing to our services, you agree to the following terms and conditions. 
            Please read them carefully.
          </p>

          <h3 className="font-semibold text-lg text-gray-800">1. Subscription and Billing</h3>
          <p>
            Your subscription will automatically renew according to the billing cycle selected at checkout (monthly or yearly) 
            unless canceled through your account settings before the renewal date. You authorize SellerAutomate to charge your 
            payment method on file for all applicable fees.
          </p>

          <h3 className="font-semibold text-lg text-gray-800">2. Refund Policy</h3>
          <p>
            We offer a 14-day free trial for new users. After the trial period ends, subscription fees are non-refundable. 
            If you choose to cancel, you will retain access to your account until the end of the current billing period.
          </p>

          <h3 className="font-semibold text-lg text-gray-800">3. Use of Services</h3>
          <p>
            SellerAutomate grants you a non-exclusive, non-transferable license to use the platform solely for managing 
            your Amazon business. Any unauthorized reproduction, distribution, or modification of our service is strictly 
            prohibited and may result in termination of your account.
          </p>

          <h3 className="font-semibold text-lg text-gray-800">4. Account Termination</h3>
          <p>
            SellerAutomate reserves the right to suspend or terminate accounts found to be in violation of these terms, 
            engaging in fraudulent activity, or misusing the platform. Termination may result in the loss of data or features 
            associated with your account.
          </p>

          <h3 className="font-semibold text-lg text-gray-800">5. Privacy and Data Security</h3>
          <p>
            Your privacy is important to us. By using SellerAutomate, you agree to our Privacy Policy, which explains 
            how we collect, store, and protect your information. We will not share your personal data with third parties 
            without your consent except as necessary to provide the service.
          </p>

          <p>
            By clicking "I accept," you confirm that you have read and agree to these Terms and Conditions and our Privacy Policy.
          </p>
        </div>

        {/* Footer */}
        <div className="flex justify-end mt-6 space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition"
          >
            I accept
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-400 transition"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
