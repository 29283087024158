import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  connectAmazon,
  resetAmazonState,
  fetchAmazonConnections,
  deleteConnection,
} from '../features/amazon/amazonSlice';
import Spinner from '../components/Spinner';
import Modal from '../components/Modal';

const ConnectionsMangement = () => {
  const { user, token } = useSelector((state) => state.auth);
  const { isLoading, isConnected, isError, message, connections } = useSelector(
    (state) => state.amazon
  );

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isConnected) {
      toast.success('Successfully connected to Amazon!');
    }

    dispatch(resetAmazonState());
  }, [isError, isConnected, message, dispatch]);

  useEffect(() => {
    if (user && token) {
      dispatch(fetchAmazonConnections());
    }
  }, [user, token, dispatch]);

  const handleAddConnection = () => {
    dispatch(connectAmazon());
  };

  const handleDeleteClick = (connection) => {
    setSelectedConnection(connection);
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedConnection) {
      dispatch(deleteConnection(selectedConnection._id));
      setIsModalOpen(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedConnection(null);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="container mx-auto p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold text-gray-800 dark:text-gray-200 mb-5">
        Manage Connections
      </h1>

      <div className="mb-6">
        <button
          onClick={handleAddConnection}
          className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Add a Connection
        </button>
      </div>

      {connections && connections.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {connections.map((connection, index) => (
            <div
              key={index}
              className="p-6 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-md hover:shadow-lg relative"
            >
              <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-2">
                Selling Partner ID: {connection.sellingPartnerId}
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                <strong>Marketplace ID:</strong> {connection.marketplaceId}
              </p>
              <button
                onClick={() => handleDeleteClick(connection)}
                className="absolute top-2 right-2 text-gray-500 hover:text-red-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-700 dark:text-gray-300">
          No connections found. Click "Add a Connection" to get started.
        </p>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title="Confirm Delete"
        content={`Are you sure you want to delete the connection with Selling Partner ID: ${selectedConnection?.sellingPartnerId}?`}
        onSubmit={handleDeleteConfirm}
      />
    </div>
  );
};

export default ConnectionsMangement;
